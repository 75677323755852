import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { propTypes } from '../../util/types';
import {
  Page,
  SectionHero,
  LayoutSingleColumn,
  SectionExplore,
  SectionListings,
  SectionPartners,
  SectionHowItWorks,
  SectionExperience,
  SectionLocal,
  SectionTopLocations,
} from '../../components';
import { useConfiguration } from '../../context/configurationContext';

import facebookImage from '../../assets/crionFacebook-1200x630.png';
import twitterImage from '../../assets/crionTwitter-600x314.png';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import SectionMission from '../../components/SectionMission/SectionMission';

import css from './LandingPage.module.css';
import classNames from 'classnames';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    onManageDisableScrolling,
    popularListings,
    activitiesCount,
  } = props;

  const config = useConfiguration();

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      openGraphType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.marketplaceRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="LandingPage" />}
        footer={<FooterContainer />}
      >
        <div className={css.container}>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <SectionMission className={classNames(css.sectionRoot, css.paddingTop30)} />
            </li>
            <li className={css.section}>
              <SectionListings listings={popularListings} className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionHowItWorks className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionExperience className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionExplore className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionLocal className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionTopLocations className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionPartners className={css.sectionRoot} />
            </li>
          </ul>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const mapStateToProps = state => {
  const { popularListings, activitiesCount } = state.LandingPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    popularListings,
    activitiesCount,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
